<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>
    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>加入我们</span></div>
    </div>
    <div class="cont_data">
       <div class="data_list" v-for="(item,index) in list" :key="index">
          <p></p>
          <p>{{item.title}}</p>
          <p>{{item.subtitle}}</p>
          <p>{{item.info}}</p>
         <img :src="item.img" alt="">
       </div>

    </div>
    <div class="cont_contact">
       <p></p>
       <p>联系我们</p>
       <p>深度内容合作/市场品牌合作</p>
       <p>联系人：{{user.contacts }} </p>
       <p>电话：{{user.tel}}</p>
       <p>邮箱：{{user.email}}</p>
       <div>
         <img :src="user.wxewm" alt="">
       </div>
       <p>微信二维码</p>
      <p>{{user.company}}</p>
    </div>

    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Btm from "@/components/yd-Btmfooter/index"
import Navbj from "@/components/yd-Nav/nav_bj";
import {all_list, jrwm} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "join",
  data(){
    return{
        list:'',
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user','seo']),
  },

  metaInfo() {
    return {
      title: this.seo[5].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[5].description ||this.user.drp},
        { name: 'keywords', content:this.seo[5].keywords ||this.user.seo}
      ]
    };
  },
  mounted() {
    this.all_list();
  },
  methods:{
    all_list(){
      jrwm().then((res)=>{
        if (res.status == 1){
          this.list=res.data;
        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
.content{
  .cont_inform{
    width: 100%;
    height: 1.65rem;
    background: #EEEEEE;
    div{
      width: 100%;
      margin:0 auto;
      height: 1.65rem;
      line-height: 1.65rem;
      img{
        width: 0.53rem;
        height: 0.65rem;
        vertical-align: sub;
        margin-right: 9px;
        margin-left: 0.63rem;
        position: relative;
        top: -0.08rem;
      }
      font-weight: 500;
      font-size: 0.7rem;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_data{
    width: 100%;
    background: #FFFFFF;

    .data_list{
      width: 18rem;
      margin: 1.4rem auto 0;
      p:nth-child(1){
        width: 2.2rem;
        height: 0.1rem;
        background: #FFA71F;
        border-radius: 0.05rem;
      }
      p:nth-child(2){
        width: 3.75rem;
        height: 0.88rem;
        font-weight: 800;
        font-size: 0.9rem;
        color: #222222;
      }
      p:nth-child(3){
        //width: auto;
        display: inline-block;
        height: 1.38rem;
        background: #EEEEEE;
        border-radius: 0.2rem;
        text-align: center;
        line-height: 1.38rem;
        font-size: 0.8rem;
        color:#333333 ;
        margin-top:0.9rem ;
        padding: 0 0.3rem;
      }
      p:nth-child(4){
        width: 18rem;
        font-weight: 500;
        font-size: 0.75rem;
        color: #333333;
        line-height: 1.5rem;
        margin-top: 0.65rem;

      }
      img:nth-child(5){
        width:18rem;
        height: 9.57rem;
        margin-top: 1rem;
      }
    }

  }
  .cont_contact{
    background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/25.png') top center no-repeat;
    background-size: 100%;
    width: 18rem;
    height:11.95rem;
    margin: 1rem auto 2rem ;
    position: relative;
    p:nth-child(1){
      width: 2.2rem;
      height: 0.1rem;
      background: #FFA71F;
      border-radius: 0.05rem;
      position: absolute;
      top: 0;
      left: 0;
    }
    p:nth-child(2){
      font-weight: 800;
      font-size: 0.9rem;
      color: #222222;
      position: absolute;
      left: 0;
      top: 0.6rem;
    }
    p:nth-child(3){
      width: 11.05rem;
      height: 1.38rem;
      background: #EEEEEE;
      border-radius: 0.2rem;
      text-align: center;
      line-height: 1.38rem;
      font-size: 0.8rem;
      color: #333333;
      position: absolute;
      left: 0;
      top: 2.38rem;
    }
    p:nth-child(4){
      font-weight: 500;
      font-size: 0.75rem;
      color: #666666;
      position: absolute;
      top: 4.68rem;
      left: 0;
    }
    p:nth-child(5){
      font-weight: 500;
      font-size: 0.75rem;
      color: #666666;
      position: absolute;
      top: 5.6rem;
      left: 0;
    }
    p:nth-child(6){
      font-weight: 500;
       font-size: 0.75rem;
      color: #666666;
      position: absolute;
      top: 6.5rem;
      left: 0;
    }
    div{
      width: 4.5rem;
      height: 4.5rem;
      position: absolute;
      top: 8.4rem;
      left: 0;
      img{
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 0.1rem;
      }
    }
    p:nth-child(8){
      font-weight: 500;
      font-size: 0.65rem;
      color: #333333;
      position: absolute;
      top:12rem;
      left: 5rem;
    }
    p:nth-child(9){
      font-weight: 500;
      font-size: 0.75rem;
      color: #666666;
      position: absolute;
      top: 3.7rem;
      left: 0;
    }

  }

}
</style>